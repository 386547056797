<template>
  <v-dialog
    id="preview"
    v-model="preview.sync"
    container="xl"
    scrollable
  >
    <v-card>
      <div class="box" tpd="xl" halign="between">
        <div>
          {{ $t('preview-image') }}
        </div>
        <button @click="togglePreview">
          <v-icon icon="mdi-window-close" size="x-small"></v-icon>
        </button>
      </div>
      <hr class="hr" bg="5xs">
      <div tpd="xl">
        <v-img
          :lazy-src="preview.url"
          :src="preview.url"
          cover
        ></v-img>
      </div>
      <hr class="hr" bg="5xs">
      <div class="box" tpd="xl" halign="end">
        <v-btn
          prepend-icon="mdi-close"
          variant="tonal"
          :title="$t('close-btn')"
          @click="togglePreview"
        >
          {{ $t('close-btn') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'

export default {
  name: 'global-preview',
  setup() {
    
    // 글로벌 스토어 호출
    const { preview } = storeToRefs(useGlobalStore())
    const globalStore = useGlobalStore()

    return {
      preview,
      globalStore
    }
  },
  methods: {
    togglePreview () {
      this.globalStore.togglePreview()
    }
  }
}
</script>